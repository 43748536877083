import { createVuetify } from "vuetify";
import type { ThemeDefinition } from "vuetify";
import { cs, en, sk } from "vuetify/locale";
import { mdi } from "vuetify/iconsets/mdi";
import { VCard, VBtn, VField, VInput, VCheckbox, VAlert, VMenu, VListItem, VTextField, VNavigationDrawer } from "vuetify/components";
import { iconSet } from "../iconset";

export default defineNuxtPlugin((nuxtApp) => {
  const LightTheme: ThemeDefinition = {
    dark: false,
    colors: {
      surface: "#ffffff",
      background: "#f4f6f9",
      "on-background": "#141416",
      error: "#eb5757",
      aBackgroundAlt: "#ffffff",
      aLogoBg: "#03ade2",
      aInput: "#141416",
      aInputBgDisabled: "#fcfcfd",
      grayTone2: "#353945",
      grayTone3: "#777e90",
      grayTone4: "#b1b5c3",
      grayTone5: "#e6e8ec",
      grayTone6: "#f4f5f6",
      violetTone1: "#3c0078"
    }
  };

  const DarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
      surface: "#212134",
      background: "#181826",
      "on-background": "#a5a5ba",
      error: "#eb5757",
      aBackgroundAlt: "#181826",
      aLogoBg: "#212134",
      aInput: "#a5a5ba",
      aInputBgDisabled: "#181826",
      grayTone2: "#f4f5f6",
      grayTone3: "#a5a5ba",
      grayTone4: "#777e90",
      grayTone5: "#4a4a6a",
      grayTone6: "#4a4a6a",
      violetTone1: "#ffffff"
    }
  };

  const vuetify = createVuetify({
    ssr: true,
    components: {
      VCard,
      VBtn,
      VField,
      VInput,
      VCheckbox,
      VAlert,
      VMenu,
      VListItem,
      VTextField,
      VNavigationDrawer
    },
    theme: {
      defaultTheme: "light",
      themes: {
        light: LightTheme,
        dark: DarkTheme
      }
    },
    locale: {
      locale: process.env.LANGUAGE_CODE || "cz",
      fallback: "en",
      messages: { cz: cs, en, sk }
    },
    display: {
      mobileBreakpoint: "lg",
      thresholds: {
        xs: 0,
        sm: 376,
        md: 501,
        lg: 851,
        xl: 1201,
        xxl: 1441
      }
    },
    defaults: {
      global: {
        ripple: false,
        hideDetails: "auto"
      },
      VBtn: {
        flat: true,
        VProgressCircular: {
          size: "default"
        }
      },
      VCard: {
        flat: true,
        elevation: 0
      },
      VTabs: {
        height: 28,
        hideSlider: true
      },
      VTab: {
        rounded: "pill"
      },
      VExpansionPanels: {
        variant: "accordion",
        multiple: true
      },
      VAutocomplete: {
        variant: "outlined",
        density: "comfortable",
        active: true
      },
      VFileInput: {
        variant: "outlined",
        density: "comfortable"
      },
      VSelect: {
        variant: "outlined",
        density: "comfortable",
        active: true
      },
      VTextField: {
        variant: "outlined",
        density: "comfortable",
        dirty: true
      },
      VTextarea: {
        variant: "outlined",
        density: "comfortable",
        dirty: true
      },
      VAlert: {
        variant: "tonal"
      },
      VProgressCircular: {
        indeterminate: true,
        size: 50,
        width: 2
      },
      VRating: {
        activeColor: "#f9b953",
        emptyIcon: "",
        fullIcon: "ct:star",
        halfIncrements: true,
        size: 16,
        readonly: true
      }
    },
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi,
        ct: iconSet
      }
    }
  });

  nuxtApp.vueApp.use(vuetify);
});
